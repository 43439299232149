import { createContext, useContext, useEffect, useState } from "react"
import { SiteJson } from "../state/models/client"
import { siteJsonToCss } from "../pages/api/styler.css"


declare global {
    interface Window {
      __sup_site_json__: SiteJson
      __sup_client_host__: string
      __sup_client_uuid__: string
      __sup_opunit_uuid__: string
      __sup_opunit_slug: string
      __sup_opunit_country_code__: string      

        __d__: any
    }
}

export interface ClientStateType {
    siteJson: Partial<SiteJson>
    clientUuid?: string
    clientHost?: string
    opUnitSlug?: string
    opUnitUuid?: string
    opUnitCountryCode?: string
}



interface IpcMessage {
    type: string
    data: any
}


const useClientState = () => {
    const [state, setState] = useState<ClientStateType>({
      siteJson: {},
    })



    useEffect(() => {
        if (typeof window === 'undefined') {
            return
        }

        const nextdata = (window["__client_data__"] || {}) as Partial<SiteJson>


        const nextSiteJson = (window["__sup_site_json__"] || {}) as SiteJson;
        const nextclientHost = window["__sup_client_host__"] || "";
        const nextclientUuid = window["__sup_client_uuid__"] || "";
        const nextopUnitUuid = window["__sup_opunit_uuid__"] || "";
        const nextopUnitSlug = window["__sup_opunit_slug"] || "";
        const nextopUnitCountryCode = window["__sup_opunit_country_code__"] || "";


        setState({
          siteJson: nextSiteJson,
          clientHost: nextclientHost,
          clientUuid: nextclientUuid,
          opUnitSlug: nextopUnitSlug,
          opUnitUuid: nextopUnitUuid,
          opUnitCountryCode: nextopUnitCountryCode
        })
    }, [])


    useEffect(() => {
        const applyStyles = (ev: any) => {
          console.log("applyStyles/i_got_the_gibies", ev);


          try {
            const stylesData = JSON.parse(ev.data) as IpcMessage;
            if (stylesData.type === "apply") {


              console.log("applyStyles/i_got_the_gibies", stylesData.data);

              updateStyleJson(stylesData.data);
            }

          } catch (error) {
            console.error("applyStyles/error", error);            
          }

        };
    
        window.addEventListener("message", applyStyles);
    
        return () => {
          window.removeEventListener("message", applyStyles);
        };
      }, []);
    

    const updateStyleJson = (sdata: SiteJson) => {

        const datastr = siteJsonToCss(sdata);
        
        // remove old styl

        const oldStyles = document.querySelectorAll("style[data-customizer]");
        if (oldStyles) {
          oldStyles.forEach((s) => s.remove());
        }
        
        document.getElementById("iam-a-dynamic-styles")?.remove();


        // add new style
  
        const style = document.createElement("style");
        style.textContent = datastr;
        style.dataset.customizer = "true";
        document.head.appendChild(style);


        setState((prev) => {
            return {
                ...prev,
                siteJson: sdata
            } as any
        })
        
    }


    
    return state
}


export type Clienthandle = ReturnType<typeof useClientState> 

export const ClientContext = createContext<Clienthandle | null>(null)

export const useClient = () => useContext(ClientContext) as Clienthandle;


export { useClientState }