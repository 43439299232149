import { useRouter } from "next/router";
import { useClient } from "../../../hooks/useClientState";
import { useRootApp } from "../../../hooks/useRootAppState/useRootAppState";
import config from "../../../config/app";

const Footer = (props: any) => {
  const router = useRouter();
  const rootApp = useRootApp();

  const cdata = useClient();


  const siteJson = cdata.siteJson

  

  const footerContent =
    siteJson.footerContent ||
    "Copyright © 2025 Serveup. All rights reserved.";

  const feedbackEmail = `mailto:${siteJson.feedbackEmail ||
    "feedback@serveup.software"
    }`;

  const socials = siteJson.socialLinks || {};
  const appLinks = siteJson.appLinks || {};
  const footerLinks = siteJson.footerLinks || [];


  const navigateTo = (link: any) => {
    const { firstTimeSignUp, firstTimeSignUpFlag } = props;
    if (firstTimeSignUpFlag) {
      firstTimeSignUp({ firstTimeSignup: false });
    }
    // firstTimeSignUp({ firstTimeSignup: false });
    router.push(link);
  };

  return (
    <div className="sup-footer mt-10">
      <div className="flex p-[20px] flex-col items-start gap-[20px] self-stretch md:max-w-[1100px] md:w-full md:mx-auto">

        <div className="flex p-[20px] flex-col items-start gap-[20px] self-stretch md:max-w-[1100px] md:w-full md:mx-auto">

          {footerLinks.map((link) => (
            <div
              className="flex justify-between items-start self-stretch sup-footer-link cursor-pointer"
              onClick={() => navigateTo(link.link)}
            >
              <p className="text-[16px] font-semibold font-font-family-body">
                {link.title}
              </p>
              <div className="flex w-[16px] h-[16px] py-[1px] justify-center items-center my-auto relative">
                <svg>
                  <use href="/sprites/sprites.svg#whiteArrow" />
                </svg>
              </div>
            </div>
          ))}
        </div>

        <div className="flex items-center gap-[8px] w-fit mx-auto sup-apps">
          {Object.keys(appLinks).map((appName) => (
            <>
              <div className="w-[119.665px] h-[40px] relative sup-app-item">
                <svg>
                  <use href={`/sprites/sprites.svg#${appName}`} />
                </svg>
              </div>
            </>
          ))}
        </div>

        <div className="flex gap-[20px] items-center w-fit mx-auto sup-social">
          {Object.keys(socials).map((socialName) => (
            <div className="w-[20px] h-[20px] relative sup-social-item">
              <a href={(socials as any)[socialName] as string}>
                <svg>
                  <use href={`/sprites/sprites.svg#${socialName}`} />
                </svg>
              </a>
            </div>
          ))}

          {config.showDebugUI && (
            <>
              <button
                type="button"
                onClick={() => {
                  console.log("@rootApp", rootApp);
                }}
              >
                DEBUG
              </button>
            </>
          )}
        </div>

        <p className="text-center text-[12px] font-font-family-body w-fit mx-auto sup-copyright">
          {getDateFixedCopyrightText(footerContent)}
        </p>
      </div>
    </div>
  );
};

const getDateFixedCopyrightText = (text: string) => {
  const yearRegex = /\d{4}/;
  const match = yearRegex.exec(text);
  if (match) {
    const currentYear = new Date().getFullYear();
    if (match[0] !== String(currentYear)) {
      return text.replace(yearRegex, String(currentYear));
    }
  }
  return text;
};

export default Footer;
