import { httpClient } from "../http"
import * as qtype from "./storeQueryTypes"

export interface TagData  {
    id: number;
    name: string;
    tagType: "SINGLE" | "MULTIPLE";
    StoreTagGroupItems: {
        id: number;
        tagGroupId: number;
        name: string;
    }[];
}


export interface QueryResult2 {
    stores: qtype.StoreInfo[];
    tagData: TagData[]
}

export interface QueryOptions {
    serviceType?: string
    location?: qtype.Location
    tagGroupItemIds: number[]
    channelType: "WEBSITE" | "MOBILE"
}


export const queryStoresByTags = async (opts: QueryOptions ) => {
    return httpClient.post<QueryResult2>("/client/storeTags/public/stores", opts)
}
