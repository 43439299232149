// import { CStyle } from "./definations";

type CStyle = any 



const fontSizeMapping: Record<string, string> = {
    zero: "0",
    extrasmall: "0.75rem",
    small: "0.875rem",
    medium: "1rem",
    large: "1.125rem",
    xlarge: "1.25rem",
}

const fontLineHeightMapping: Record<string, string> = {
    zero: "0",
    extrasmall: "1rem",
    small: "1.25rem",
    medium: "1.5rem",
    large: "1.75rem",
    xlarge: "2rem",
}



const fontWeightMapping: Record<string, string> = {
    none: "normal",
    thin: "100",
    extralight: "200",
    light: "300",
    normal: "400",
    medium: "500",
    semibold: "600",
    bold: "700",
    extrabold: "800",
}

const color = (c: string)  => {
    if (c.startsWith("#")) {
        return c;
    }
    return `var(--${c})`;
}


const borderWidthMapping: Record<string, string> = {
    zero: "0px",
    extrasmall: "1px",
    small: "2px",
    medium: "4px",
    large: "8px",
    xlarge: "16px",
}

const boxShadowMapping: Record<string, string> = {
    extrasmall: "0 1px 2px 0 var(--sup-box-shadow-color, rgba(0,0,0,0.1))",
    small: "0 1px 3px 0 var(--sup-box-shadow-color, rgba(0,0,0,0.1)), 0 1px 2px -1px var(--sup-box-shadow-color, rgba(0,0,0,0.1))",
    medium: "0 4px 6px -1px var(--sup-box-shadow-color, rgba(0,0,0,0.1)), 0 2px 4px -2px var(--sup-box-shadow-color, rgba(0,0,0,0.1))",
    large: "0 10px 15px -3px var(--sup-box-shadow-color, rgba(0,0,0,0.1)), 0 4px 6px -4px var(--sup-box-shadow-color, rgba(0,0,0,0.1))",
    xlarge: "0 20px 25px -5px var(--sup-box-shadow-color, rgba(0,0,0,0.1)), 0 8px 10px -6px var(--sup-box-shadow-color, rgba(0,0,0,0.1))",   
}

const dropShadowMapping: Record<string, string> = {
    extrasmall: "drop-shadow(0 1px 1px rgb(var(--sup-drop-shadow-color) / 0.05))",
    small: "drop-shadow(0 1px 2px rgb(var(--sup-drop-shadow-color) / 0.1)) drop-shadow(0 1px 1px rgb(var(--sup-drop-shadow-color) / 0.06))",
    medium: "drop-shadow(0 4px 3px rgb(var(--sup-drop-shadow-color) / 0.07)) drop-shadow(0 2px 2px rgb(var(--sup-drop-shadow-color) / 0.06))",
    large: "drop-shadow(0 10px 8px rgb(var(--sup-drop-shadow-color) / 0.04)) drop-shadow(0 4px 3px rgb(var(--sup-drop-shadow-color) / 0.1))",
    xlarge: "drop-shadow(0 20px 13px rgb(var(--sup-drop-shadow-color) / 0.03)) drop-shadow(0 8px 5px rgb(var(--sup-drop-shadow-color) / 0.08))",
    xxlarge: "drop-shadow(0 25px 25px rgb(var(--sup-drop-shadow-color) / 0.15))",
    none: "drop-shadow(0 0 #0000)",
}


const paddingMapping: Record<string, string> = {
    zero: "0",
    extrasmall: "0.125rem",
    small: "0.25rem",
    medium: "0.5rem",
    large: "0.75rem",
    xlarge: "1rem",
}


const borderRadiusMapping: Record<string, string> = {
    ...paddingMapping, 
    full: "9999px",
}

const marginMapping = paddingMapping;


export const convertJsonToCss = (  json: Record<string, Partial<CStyle>>, prefix="") => {
    let css = "";

    


    Object.keys(json).forEach((key) => {
       
        const styleProps = json[key];
        if (styleProps) {
            css += `${key} {`;
            if (styleProps.textColor) {
                css += `color: ${color(styleProps.textColor)};`;
            }
            if (styleProps.bgColor) {
                css += `background-color: ${color(styleProps.bgColor)};`;
            }
            if (styleProps.borderColor) {
                css += `border-color: ${color(styleProps.borderColor)};`;
            }
            if (styleProps.borderRadius) {
                css += `border-radius: ${borderRadiusMapping[styleProps.borderRadius]};`;
            }

            if (styleProps.borderStyle) {
                css += `border-style: ${styleProps.borderStyle};`;
            }

            if (styleProps.borderWidth) {
                css += `border-width: ${borderWidthMapping[styleProps.borderWidth]};`;
            }
            
            if (styleProps.boxShadowColor) {
                css += `--sup-box-shadow-color: ${color(styleProps.boxShadowColor)};`;
            }

            if (styleProps.dropShadowSize) {
                css += `filter: ${dropShadowMapping[styleProps.dropShadowSize]};`;
            }

            if (styleProps.dropShadowColor) {
                css += `--sup-drop-shadow-color: ${color(styleProps.dropShadowColor)};`;
            }

            if (styleProps.boxShadowSize) {
                css += `box-shadow: ${boxShadowMapping[styleProps.boxShadowSize]};`;                
            }
           

            if (styleProps.fontSize) {
                css += `font-size: ${fontSizeMapping[styleProps.fontSize]};`;
                css += `line-height: ${fontLineHeightMapping[styleProps.fontSize]};`;
            }

            if (styleProps.fontFamily) {
                css += `font-family:  var(--font-${styleProps.fontFamily});`;
            }

            if (styleProps.fontWeight) {
                css += `font-weight: ${fontWeightMapping[styleProps.fontWeight]};`;
            }

            if (styleProps.paddingTop) {
                css += `padding-top: ${paddingMapping[styleProps.paddingTop]};`;
            }

            if (styleProps.paddingLeft) {
                css += `padding-left: ${paddingMapping[styleProps.paddingLeft]};`;
            }

            if (styleProps.paddingRight) {
                css += `padding-right: ${paddingMapping[styleProps.paddingRight]};`;
            }

            if (styleProps.paddingBottom) {
                css += `padding-bottom: ${paddingMapping[styleProps.paddingBottom]};`;
            }

            if (styleProps.marginTop) {
                css += `margin-top: ${marginMapping[styleProps.marginTop]};`;
            }

            if (styleProps.marginLeft) {
                css += `margin-left: ${marginMapping[styleProps.marginLeft]};`;
            }

            if (styleProps.marginRight) {
                css += `margin-right: ${marginMapping[styleProps.marginRight]};`;
            }

            if (styleProps.marginBottom) {
                css += `margin-bottom: ${marginMapping[styleProps.marginBottom]};`;
            }

            // display

            if (styleProps.display) {
                css += `display: ${styleProps.display};`;
            }

            if (styleProps.flexJustifyContent) {
                css += `justify-content: ${styleProps.flexJustifyContent};`;
            }

            if (styleProps.flexAlignItems) {
                css += `align-items: ${styleProps.flexAlignItems};`;
            }

            if (styleProps.justifySelf) {
                css += `justify-self: ${styleProps.justifySelf};`;
            }

            if (styleProps.alignSelf) {
                css += `align-self: ${styleProps.alignSelf};`;
            }




            css += "}";
            css + " \n";
            

        }

        css += " \n";
       
    });

    return css;
    
    
}


/*

    "fonts": {
        "primary": "Rubik Vinyl",
        "primaryURL": "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Vinyl&display=swap",
        "secondaryURL": "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Vinyl&display=swap",
        "secondary": "Rubik Vinyl"
    },

*/


export const convertFontAttrsToCss = (font: Record<string, string>) => {
    let css = "";
   
    if (font.primaryURL) {
        css += `@import url('${font.primaryURL}');\n`;
    }

    if (font.secondaryURL) {
        css += `@import url('${font.secondaryURL}');\n`;
    }

    css += "*, ::before, ::after  {";

    if (font.primary) {
        css += `--font-primary: "${font.primary}";\n`;
    }

    if (font.secondary) {
        css += `--font-secondary: "${font.secondary}";\n`;
    }

    css += "} \n\n";

    return css;

}