import "../styles/globals.scss";
import "../styles/_colors.scss";
import ErrorBoundary from "./_error";
import React from "react";
import type { AppProps } from "next/app";
import NavSection from "../containers/layout/Nav/NavSection";
import FooterSection from "../containers/layout/Footer/FooterSection";
import { useRouter } from "next/router";
import { decrypt } from "../utils/commonFunction";
import WithMainModal from "../components0/modal/WithMainModal";
import SuperCartProvider from "../containers0/MenuItemRenderer/SuperCart/Provider";
import { ClientContext, useClientState } from "../hooks/useClientState";
import { APIProvider as GoogleAPIProvider } from "@vis.gl/react-google-maps";
import config from "../config/app";
import {
  AppContext,
  useRootAppState,
} from "../hooks/useRootAppState/useRootAppState";
import ActiveStoreBar from "../ui-update/ActiveStoreBar";

const { ECommerce } = config;

declare global {
  interface Window {
    log_override_override?: boolean;
    __d__: any;
  }
}

const maybeLog =
  (func: Function) =>
  (...args: any[]) => {
    if (typeof window !== "undefined" && window["log_override_override"]) {
      func(...args);
    }
  };

const OverRideLogger = () => {
  console.error = maybeLog(console.error);
  console.log = maybeLog(console.log);
  console.debug = maybeLog(console.debug);
  console.warn = maybeLog(console.warn);
};

function MyApp({ Component, pageProps }: AppProps) {
  // OverRideLogger()

  if (typeof window !== "undefined") {
    window["__d__"] = decrypt;
  }

  const router = useRouter();
  const ClientData = useClientState();
  const rootApp = useRootAppState();

  console.log("@debug_cdata", ClientData);

  return (
    <div>
      <ErrorBoundary>
        <GoogleAPIProvider 
          apiKey={(ECommerce.GOOGLE_MAPS_KEY) as string} 
          libraries={["places", "maps", "geometry"]}
          >
          <ClientContext.Provider value={ClientData}>
            <AppContext.Provider value={rootApp}>
              <SuperCartProvider>
                <WithMainModal>
                  <div>
                    <NavSection />
                    <div className="pt-[80px]">
                      <ActiveStoreBar handle={rootApp.activeStore} />
                      <Component {...pageProps} />
                    </div>
                    <FooterSection />
                  </div>
                </WithMainModal>
              </SuperCartProvider>
            </AppContext.Provider>
          </ClientContext.Provider>
        </GoogleAPIProvider>
      </ErrorBoundary>
    </div>
  );
}

export default MyApp;
