import Link from "next/link";
import { StoreInfo } from "../../api/store/storeQueryTypes";
import Button from "../button";
import { ServiceType } from "../../hooks/useActiveStore/useActiveStore";
import { StoreDetailedInfo } from "../../state/models";

export interface PropsType {
  onServiceTypeSelect?: (serviceType: ServiceType) => void;
  store: StoreInfo;
  details?: boolean;
}

const AddressBlob = (props: PropsType) => {

    const store = props.store;

  return (
    <div className="flex gap-[16px] flex-col items-start self-stretch p-[16px] w-full rounded-[8px] sup-card md:gap-[12px] sup-store-card">
      <div className="flex flex-col gap-[8px]">
        <h3 className="text-[16px] font-font-family-body font-semibold">
          {props.store.name}
        </h3>

        {!!store.distance && (
          <>
            <h3 className="text-[12px] font-font-family-body font-normal italic">
              {store.distance.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              km away
            </h3>
          </>
        )}
      </div>

      {/* TAGS */}

      {store.status === "offline" && (
        <>
          <Tag text={"Temporary Closed"} acentColor={"bg-red-500"} tagSlug={store.status} />
        </>
      )}

      {store.status === "closed" && (
        <>
          <Tag text={"Closed"} acentColor={"bg-red-500"} tagSlug={store.status} />{" "}
        </>
      )}

      {store.status === "closing-soon" && (
        <>
          <Tag
            text={`Closing soon ${store.openTime?.split("-")[1] || ""}`}
            acentColor={"bg-yellow-500"}
            tagSlug={store.status}
          />
        </>
      )}

      {store.status === "open-24h" && (
        <>
          <Tag 
          text={"Open 24hrs"} 
          acentColor={"bg-accents-primary-accent"} 
          tagSlug={store.status}
          
          />
        </>
      )}

      {store.status === "open" && (
        <>
          <Tag
            text={`Open ${store.openTime}`}
            acentColor={"bg-accents-primary-accent"}
            tagSlug={store.status}
          />
        </>
      )}

      {store.status === "closed-opens-today" && (
        <>
          <Tag
            text={`Opens at ${store.openTime?.split("-")[0] || ""}`}
            acentColor={"bg-yellow-500"}
            tagSlug={store.status}
          />
        </>
      )}

      {store.status === "opening-soon" && (
        <>
          <Tag
            text={`Opening soon ${store.openTime}`}
            acentColor={"bg-yellow-500"}
            tagSlug={store.status}
          />
        </>
      )}





      <div className="flex flex-col gap-[20px] py-[16px] w-full">
        <p className="text-[14px] font-font-family-body font-normal">
          Choose an option to order
        </p>
        <div className="flex gap-[12px] items-center self-stretch text-white">
          <Button
            text={"Delivery"}
            bgColor={"sup-service-btn sup-service-btn-delivery" }
            onClick={(ev) => {
              ev.stopPropagation();
              props.onServiceTypeSelect && props.onServiceTypeSelect("DELIVERY");
            }}
          />
          <Button
            text={"Pick up"}
            onClick={(ev) => {
              ev.stopPropagation();
              props.onServiceTypeSelect && props.onServiceTypeSelect("TAKE_AWAY");

            }}
            bgColor={"sup-service-btn sup-service-btn-pickup"}
          />
        </div>
      </div>
      {!props.details ? (
        <></>
      ) : (
        <div className="flex gap-[10px] justify-between w-full">
          <Link
            href={`/store-locator/${store.uuid}-${store.id}`}
            className="text-[14px] font-font-family-body font-normal sup-link"
          >
            Store details
          </Link>
        </div>
      )}
    </div>
  );
};

interface TagProps {
  text: string;
  tagSlug: string;
  acentColor:
    | "bg-accents-secondary-accent"
    | "bg-accents-primary-accent"
    | string;
}

const Tag = (props: TagProps) => {
  return (
    <div className={`px-[10px] py-[8px] rounded-[9999px] text-white ${props.acentColor} sup-store-tag sup-store-tag-${props.tagSlug}`}>
      <p className="text-[12px] font-font-family-body font-normal leading-[10px]">
        {props.text}
      </p>
    </div>
  );
};

export default AddressBlob;
