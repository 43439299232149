import type { NextPage } from "next";
import Homepage from "../../containers/main/Homepage";
import config from "../../config/app";
import { commonISRApi, getPublicOpUnits } from "../../state/commonApiService";
import { PathContext } from "../../state/models/path";

const Home: NextPage = (props: any) => {
  let data = [];

  if (!props.notFound) {
    data = props.bannerImage;
  }

  return (
    // <div className={styles.container}>
    // </div>
    (<Homepage data={data} />)
  );
};



export async function getStaticProps(context: PathContext) {
  
  console.log("@context", context);

  console.log("getStaticProps/index", context);

  const urlBanner = config.ECommerce.BASE_URL + "/clientImages/home";

  try {
    const bannerImage = await commonISRApi(context.params.host, "GET", urlBanner);
   
    if (!bannerImage) {
      return { props: { notFound: true } };
    } else {
      return { props: { bannerImage }, revalidate: 15 };
    }
  } catch (error: any) {
    return { props: { notFound: true } };
  }
}

export async function getStaticPaths() {

  console.log("getStaticPaths/index");

  const units = await getPublicOpUnits()
  console.log("units", units);


  return {
    paths: units.map((unit) => ({ params: { ...unit, host: unit.websiteSlug } })),
    fallback: "blocking",
  };
}

export default Home;
